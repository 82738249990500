import { call } from '@/bridge';
import { getUrlParamByKey } from '@/module/url';
import { DevicePlatformType, AppEnvType } from '@/types/common';
import { isPlainObject } from 'lodash-es';
import { detectStokenEnv } from './login';
import { getCookie } from '@/module/cookie';
/**
 *
 * @param v Function 判断是否是函数
 * @returns boolean
 */
export function isFunc(v: any): boolean {
  return typeof v == 'function';
}
export function isUndefined(v: any): boolean {
  return typeof v == 'undefined';
}
export function isDate(v: any): boolean {
  return v != 'Invalid Date' && Object.prototype.toString.call(v) == '[object Date]';
}

/**
 * 将枚举的 unspecified 设置为空
 * @param v
 * @returns
 */
export function parseEnumValue(v: any) {
  return v == 'unspecified' ? '' : v;
}
/**
 * 支持字符串，对象，数组的判断
 * @param v
 * @returns
 */
export function isEmpty(v: any) {
  if (v == null || parseEnumValue(v) === '' || typeof v == 'undefined') {
    return true;
  }
  if (typeof v == 'object' && !Object.keys(v).length) return true;
  return false;
}
/**
 * 兼容字符串'0'的情况
 * @param v
 */
export function isStrictEmpty(v: any) {
  return isEmpty(v) || v === '0' || v === undefined;
}

/**
 * 判断是否为nuber类型
 * @param v number
 * @returns
 */
export function isNumber(v: any): boolean {
  return typeof v === 'number' && Number.isFinite(v);
}
export function isPureObject(v: any): boolean {
  return Object.prototype.toString.call(v) == '[object Object]';
}
export function throttle(fn: (...args: any[]) => void, time = 300) {
  let pre: number = 0;
  return function (...args: any[]) {
    const now = Date.now();
    if (now - pre > time) {
      pre = now;
      // eslint-disable-next-line prefer-spread
      fn.apply(null, args);
    }
  };
}
export function debounce(fn: (...args: any[]) => void, delay = 300, immediate = false) {
  let timer: any;
  let result;
  return function (...args: any[]) {
    if (timer) clearTimeout(timer);

    if (immediate) {
      // 如果timer存在，说明第二次调用的时候还没到delay时间，因为如果超过delay时间
      // timer会被赋值为null，所以这个时候我们不应该执行fn，应该重新设置一个定时器
      // 但如果是一次的时候，因为还没有设过定时器，所以这里timer会是undefined
      if (timer) {
        timer = setTimeout(() => {
          timer = null;
        }, delay);
      } else {
        // @ts-ignore
        result = fn.apply(this, args);
        return result;
      }
    } else {
      // @ts-ignore
      timer = setTimeout(() => fn.apply(this, args), delay);
    }
  };
}
/**
 *
 * @param hidden 是否全局不滚动
 */
export function toggleBodyScroll(hidden: boolean = true) {
  document.body.style.overflow = hidden ? 'hidden' : 'auto';
}
const UA = window.navigator.userAgent;

export function getUaVal(key: string) {
  const reg = new RegExp(`\\s${key}=([^\\s]+)`, 'i');
  return UA.match(reg)?.[1];
}

export function isIOS() {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
}

export function isAndroid() {
  const u = navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
}

export function isPC() {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    )
  ) {
    return false;
  }

  return true;
}
export const isShopeeApp = /Shopee/i.test(UA);

export const APP_ENV = (() => {
  if (!isShopeeApp) return 0;
  const m = UA.match(/\sapp_type=([^\s]*)/i);
  if (m) return m[1];
  return 0;
})();
export const isShopeePayApp = APP_ENV == AppEnvType.ShopeePay;
export const isShopeeMainApp = APP_ENV == AppEnvType.Shopee;

export function getAppVersion() {
  if (!isShopeeApp) return '0';
  const m = UA.match(/\sappver=([^\s]*)/i);
  if (m) return m[1];
  return '1';
}

export const isCallByDrawer = !!window.ReactNativeWebView;

// NOTE 每次在此处添加query，请记得去补充login.ts中的legalURLParams白名单参数

type GetArrayItem<T> = T extends [infer L, ...infer R] ? L & GetArrayItem<R> : {};

export function assignShallow<P extends any[]>(...list: P): GetArrayItem<P> {
  const ret = list[0];
  for (let i = 1; i < list.length; i++) {
    const item = list[i];
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key) && !isUndefined(item[key])) {
        ret[key] = item[key];
      }
    }
  }
  return ret;
}
/**
 * 将参数中的时间转化为unix时间戳毫秒数
 * @param {number/string/datetime} val 待转换的时间，有4种格式：
 *  1）参数为`yyyy/MM/dd h:m:s`格式的过时间字符串，绝对时间，兼容(yyyy-MM-dd h:m:s和yyyy.MM.dd h:m:s)；
 *  2）参数为日期对象，绝对时间；
 *  3）参数为数字，绝对时间，unix时间戳；
 *  4）格式为`\d+[smhd]`，其中s 表示秒、m 分钟、h 小时、d 天，如 30d，转换成30天的毫秒数
 * @returns {number} unix时间戳,参数不正确时，返回0
 * @function convertTimestamp
 * @example
 * convertEndTime("30d")  // 30天的时间
 */
export function convertTimestamp(
  val: number | Date | string = 0,
  current: Date = new Date(),
): number {
  // 1.如果是数字，绝对时间，unix时间戳
  if (typeof val === 'number') {
    return val;
  }
  // 2.如果是日期对象，表示该日期的Unix时间戳
  if (isDate(val)) {
    return (val as Date).getTime();
  }
  if (typeof val == 'string') {
    const matches = val.match(/^([+-]?)(\d+(?:\.\d+)?)([smhdy])$/);
    // 3.如果是时间字符串 eg：1d，2h, 3m, 4s
    if (matches) {
      let ms = 0;
      const n = +matches[2];
      switch (matches[3]) {
        case 'y':
          // eslint-disable-next-line no-case-declarations
          const now = current.getTime();
          current.setFullYear(current.getFullYear() - n);
          ms = now - current.getTime();
          break;
        case 'm':
          ms = n * 60 * 1000;
          break;
        case 'h':
          ms = n * 60 * 60 * 1000;
          break;
        case 'd':
          ms = n * 24 * 60 * 60 * 1000;
          break;
        case 's':
        default:
          ms = n * 1000;
      }
      if (matches[1] == '-') {
        return -ms;
      }
      return ms;
    }
    // 4.如果传入的是绝对时间字符串，eg：2018/12/25 09:30:45  2018-12-25 09:30:45 2018.12.25 09:30:45
    val = val.replace(/[.-]/g, '/');
    const d = new Date(val).getTime();
    if (!isNaN(d)) {
      return d;
    }
  }
  return 0;
}

export function bound(position: number, min: number | undefined, max: number | undefined) {
  let ret = position;
  if (min !== undefined) {
    ret = Math.max(position, min);
  }
  if (max !== undefined) {
    ret = Math.min(ret, max);
  }
  return ret;
}

type Merge<T, P> = {
  [K in keyof T & keyof P]: P[K] | T[K];
};

export function mergeFuncProps<T extends Record<string, any>, P extends Record<string, any>>(
  p1: T,
  p2: P,
): Merge<T, P> {
  const p1Keys = Object.keys(p1);
  const p2Keys = Object.keys(p2);
  const keys = new Set([...p1Keys, ...p2Keys]);
  const res: any = {};

  keys.forEach((key) => {
    const p1Value = p1[key];
    const p2Value = p2[key];

    if (typeof p1Value === 'function' && typeof p2Value === 'function') {
      res[key] = function (...args: any[]) {
        p1Value(...args);
        p2Value(...args);
      };
    } else {
      res[key] = p1Value || p2Value;
    }
  });

  return res;
}

/**
 * 用于判断当前页面是否需要重新登录
 * 满足FORCE_LET_GO_LIST白名单条件，不做容器判断直接放行
 * 满足NO_LOGIN_WHITE_LIST时满足白名单条件，非shopee APP内才放行
 */
export const NO_LOGIN_WHITE_LIST: string[] = [
  '/ec/ec-pdp.html',
  '/ec-pdp.html',
  '/ins/pdf-viewer.html',
];
const FORCE_LET_GO_LIST: string[] = [];

export function shouldGoLogin() {
  const terminal = getUrlParamByKey('terminal');
  const { pathname, host } = location || {};
  const forceLetGo = FORCE_LET_GO_LIST.includes(pathname);
  const flag = NO_LOGIN_WHITE_LIST.includes(pathname);
  const from = getUrlParamByKey('from');
  if (from === 'admin') return false;
  if (forceLetGo) return false; // 强制放行，返回false
  if (flag && (!isShopeeApp || terminal == '2')) return false; // 非shopee app内，返回false
  //  是属于s_token的登录范围，但是没有 s_token 则直接登录
  if (detectStokenEnv() && !getCookie('s_token')) {
    return true;
  }
  return !/shopee_user_id/.test(document.cookie) && /seainsure/.test(host);
}

export function safeParse(str: string) {
  if (!str) return {};
  if (typeof str == 'object') return str;
  try {
    return JSON.parse(str);
  } catch (error) {
    return {};
  }
}
export function supportIntersection() {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    return true;
  }
  return false;
}
let cacheDevicePlatform: number;
/**
 * 获取用户平台 目前是SPM有用到
 * @returns number 0 UnKnown
 * @returns number 1 iOS Web
 * @returns number 2 iOS App
 * @returns number 3 Android Web
 * @returns number 4 Android App
 * @returns number 5 PC
 * @returns number 128 Others
 */
export function getDevicePlatform(): DevicePlatformType {
  if (cacheDevicePlatform) return cacheDevicePlatform;
  let devicePlatform = DevicePlatformType.UnKnown;
  if (isPC()) {
    devicePlatform = DevicePlatformType.PC;
  } else if (isShopeeApp) {
    if (isIOS()) {
      devicePlatform = DevicePlatformType.iOSApp;
    }
    if (isAndroid()) {
      devicePlatform = DevicePlatformType.AndroidApp;
    }
  }
  if (devicePlatform === DevicePlatformType.UnKnown) {
    // 上报
    console.warn('get_user_device_platform_error', {
      userAgent: navigator.userAgent,
    });
  }
  cacheDevicePlatform = devicePlatform;
  return cacheDevicePlatform;
}
let cachedRnVersion: string | null;
export async function getRNBundleVersion() {
  if (!isShopeeApp) return '';
  if (cachedRnVersion) return cachedRnVersion;
  if (isShopeePayApp) {
    const m = UA.match(/\sRNBV=([^\s]*)/i);
    cachedRnVersion = m && m[1];
    return cachedRnVersion;
  }
  const m = UA.match(/\sshopee_rn_bundle_version=([^\s]*)/i);
  if (m && m[1]) {
    cachedRnVersion = m[1];
    return cachedRnVersion;
  }
  const dataInfo = await call('getData', { key: 'STORAGE_KEY_PLATFORM_MANIFEST_RESPONSE' });
  const { rn_version } = safeParse(dataInfo.data) || {};
  if (rn_version) {
    const versionNumberList = rn_version.split('.');
    // 转换格式， 5.8.10 -> 5008010
    cachedRnVersion = `${
      1000000 * versionNumberList[0] + 1000 * versionNumberList[1] + versionNumberList[2] * 1
    }`;
    return cachedRnVersion;
  }
  return '';
}
// 从UA或bridge获取rn_bundle_version 优先取UA
// export const getRNBundleVersion = async () => {
//   if (!isShopeeApp) return '';
//   let regExp = new RegExp('');
//   let dataInfo = {} as any;
//   switch (APP_ENV) {
//     case AppEnvType.Shopee:
//       regExp = /\sshopee_rn_bundle_version=([^\s]*)/i;
//       // 目前仅Shopee提供这个，这里也是用于解决Shopee Android App UA无法获取到版本的问题
//       dataInfo = await getData();
//       break;
//     case AppEnvType.ShopeePay:
//       regExp = /\sRNBV=([^\s]*)/i;
//       break;
//     default:
//   }
//   const m = UA.match(regExp);

//   let rnVersionStr = '';
//   const { rn_version } = safeParse(dataInfo.data) || {};
//   // 如果有rn_version 转换成对应的格式
//   if (rn_version) {
//     const versionNumberList = rn_version.split('.');
//     // 转换格式， 5.8.10 -> 5008010
//     rnVersionStr = `${
//       1000000 * versionNumberList[0] + 1000 * versionNumberList[1] + versionNumberList[2] * 1
//     }`;
//   }
//   if ((!m || !m[1]) && !rnVersionStr) {
//     console.error('get_rn_version', {
//       UA,
//       rnVersionData: dataInfo.data,
//     });
//     return '';
//   }

//   return (m && m[1]) || rnVersionStr;
// };

export const myOmitBy = (target: any, obj: any) => {
  if (!obj || Object(obj).length === 0 || !target || Object(target).length === 0) {
    return target;
  }
  const res: Record<string, any> = {};
  Object.keys(target).map((key) => {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      res[key] = target[key];
    } else if (isPlainObject(target[key])) {
      res[key] = myOmitBy(target[key], obj[key]);
    }
  });
  return res;
};

export const convertToObject = (key: any, value: any) => {
  const result: any = {};
  const keys = key.split('.');
  let currentObj = result;
  for (let i = 0; i < keys.length; i++) {
    const nestedKey = keys[i];

    if (nestedKey !== '') {
      if (!Object.prototype.hasOwnProperty.call(currentObj, nestedKey)) {
        currentObj[nestedKey] = {};
      }

      if (i === keys.length - 1) {
        currentObj[nestedKey] = value;
      } else {
        currentObj = currentObj[nestedKey];
      }
    }
  }
  return result;
};
